<template>
  <Header />
  <div class="w-full font-basic text-text-0 mt-20">
    <!-- 상단 -->

    <div
      class="  lg:py-24 lg_tall:py-24 sm:py-2 lg_tall:text-title-size lg:text-title-size sm:text-medium-size font-basic text-text-0">

      <div class="border-t-4 border-main-0 "></div>

      <!-- <img class="lg:w-[1920px] lg:h-[500px] rounded-3xl lg:object-fill lg_tall:object-fill sm:object-cover"
        src="https://d3hprqr5b1tsx3.cloudfront.net/img/202312/img_company_001.PNG" alt=""> -->
      
      <div
        class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 lg:mt-16 lg_tall:mt-16 sm:mt-10 font-bold sm:items-center lg_tall:text-title-size lg:text-title-size sm:text-content-size">
        <p class="">"<span class="text-main-0 ">리드(Lead)</span>하며 함께 <span class="text-main-0">걷자(Walk)</span>"라는 마음가짐에서
          출발한 리드워크</p>
      </div>
      <div class="lg:mt-16 lg_tall:mt-16 sm:mt-10"></div>
      <!-- <img class="rounded-3xl lg:object-fill lg_tall:object-fill sm:object-cover"
        src="https://d3hprqr5b1tsx3.cloudfront.net/img/202312/img_company_001.PNG" alt=""> -->

      <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10">
        <p
          class="font-semibold lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-text-0 up-animation-0">
          기술로 새로운 길을 만들고 함께 걸어가고 있습니다.</p>
        <p class="lg:mt-10 lg_tall:mt-10 sm:mt-10 text-content-size text-text-1 up-animation-1">기술을 융합하여 즐겁게 활용하는 팀을
          만들자는 기조 하에 설립한 리드워크는 차근차근 걸어가고
          있습니다.</p>
        <p class="text-content-size text-text-1 up-animation-1">다음 세대(next generation)의 더 나은 삶을 고민하는 개척자들 입니다.</p>
        <p class="mt-8 text-content-size text-text-1 up-animation-2 lg:hidden lg_tall:hidden">
          L-walk에는 급변화하는 IT 시장에서 QA 컨설팅과 다양한 경험을 갖춘 엔지니어들로 고객사의 신뢰하는 검증 서비스와 AR(증강현실)
          ,이미지 러닝 기술을 바탕으로 커머스 플랫폼 서비스 '겟아이즈'와 큐밋(런칭 준비중)으로 더 나은 미래를 향해 달려가고 있습니다.
        </p>
        <p class="mt-8 text-content-size text-text-1 up-animation-2 sm:hidden">L-walk에는 급변화하는 IT 시장에서 QA 컨설팅과 다양한 경험을 갖춘
          엔지니어들로 고객사의
          신뢰하는 검증
          서비스와
          AR(증강현실),<br class="lg_tall:hidden">이미지 러닝 기술을 바탕으로 커머스 플랫폼 서비스 '겟아이즈'와 큐밋(런칭 준비중)으로 더 나은 미래를
          향해
          달려가고
          있습니다.
        </p>
      </div>

      <!-- 회사소개서 -->
      <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 flex sm:gap-4 items-center mt-10 up-animation-3">
        <a class="w-[200px] py-2 border border-text-3 flex justify-around hover:cursor-pointer items-center"
          target="_blank"
          href="https://d3hprqr5b1tsx3.cloudfront.net/Info/24%EB%85%84%20L-walk%20%ED%85%8C%EC%8A%A4%ED%8A%B8%20%EC%84%9C%EB%B9%84%EC%8A%A4%20%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C%EC%84%9C_v1.0.pdf">
          <div class="lg:hidden lg_tall:hidden">
            <p class="text-text-0 text-small-size">회사소개서</p>
            <p class="text-text-0 text-small-size">다운로드</p>
          </div>
          <div class="sm:hidden">
            <p class="text-text-0 text-small-size">회사 소개서 다운로드</p>
          </div>
          <img class="w-5 h-5" src="@/assets/ic_down.png" alt="">
        </a>
        <a class="w-[220px] lg:ml-10 lg_tall:ml-10 py-2 border border-text-3 flex justify-around hover:cursor-pointer items-center"
          target="_blank" href="https://d3hprqr5b1tsx3.cloudfront.net/Info/geteyes-소개서.pdf">
          <div>

          </div>
          <p class="text-text-0 text-small-size">겟아이즈 소개서 다운로드</p>
          <img class="w-5 h-5" src="@/assets/ic_down.png" alt="">
        </a>
      </div>

    </div>

    <!-- 리드워크 스토리 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 bg-slate-50">
      <p class="font-semibold lg:text-title-size lg_tall:text-title-size  sm:text-medium-size pl-[10%]">Related News</p>
      <p
        class="lg:mt-10 lg_tall:mt-10 sm:mt-5 lg:text-medium-size lg_tall:text-medium-size sm:text-content-size pl-[10%]">
        언론에 비친 리드워크를 만나보세요</p>
      <div class="mx-10 mt-10">
        <Carousel class="" v-bind="settings" :autoplay="3000" :wrap-around="true" :items-to-scroll="1"
          :items-to-show="itemsToShow" :snap-align="center" :dir="ltr">
          <Slide class="" v-for="slide in slides" :key="slide">
            <CompanyStory :image="slide.image" :content="slide.content" :title="slide.title"
              :clickUrl="slide.clickUrl" />
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>

        </Carousel>
      </div>
    </div>

    <div class="flex sm:flex-wrap justify-between">
      <!-- 리드워크 발자취 -->
      <div class="lg:pt-24 lg_tall:pt-24 sm:pt-16 px-[10%] overflow-auto">
        <p class="font-semibold lg:text-title-size lg_tall:text-title-size sm:text-medium-size">리드워크 발자취</p>
        <p class="lg:mt-10 lg_tall:mt-10 sm:mt-5 lg:text-medium-size-1 lg_tall:text-medium-size-1 sm:text-content-size">
          리드워크는 오늘도 열심히 역사를 만들고 있습니다.</p>
        <div class="flex h-[66vh] items-center justify-center px-6 md:px-60 relative mt-10">
          <div class="space-y-6 h-[66vh] border-l-2 border-solid absolute left-[40%]">
            <div v-for="(year, index) in Object.keys(companyHistoryData).reverse()" :key="year"
              class="w-full cursor-pointer" @click="handleClick(year)">
              <i :class="['absolute fa-regular fa-circle-dot bg-white z-8 -ml-[17px] fa-2x', selectedYear === year ? 'text-main-0' : 'text-main-1']"
                :style="{ top: 24 * index + '%' }"></i>
              <p :class="['absolute -ml-[80px] text-content-size font-semibold', selectedYear === year ? 'text-main-0' : 'text-main-1']"
                :style="{ top: 24 * index + '%' }">{{ year }}</p>
            </div>
          </div>
          <ul ref="yearData" class="ml-20 self-start absolute left-[40%]">
            <li v-for="item in currentYearData" :key="item.day" class="mb-10">
              <p>{{ item.day }}</p>
              <p>{{ item.content }}</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 찾아오는 길 -->
      <div class="lg:py-24 lg_tall:py-24 sm:py-16 lg:px-[10%] lg_tall:px-[10%] sm:px-10 sm:w-[100%]">
        <p class="lg:text-title-size lg_tall:text-title-size sm:text-medium-size font-semibold">리드워크 찾아오는 길</p>
        <div class="mt-10"></div>
        <div class="flex lg:text-content-size lg_tall:text-content-size sm:text-small-size">
          <p class="font-semibold lg:basis-[12%] lg_tall:basis-[12%] sm:basis-[18%]">주소</p>
          <p class="sm:hidden">서울특별시 금천구 디지털로9길 41 삼성 IT 해링턴타워 10층 1016호</p>
          <div class="lg:hidden lg_tall:hidden">
            <p class="">서울특별시 금천구 디지털로9길 41</p>
            <p class="">삼성 IT 해링턴타워 10층 1016호</p>
          </div>
        </div>
        <div class="flex lg:text-content-size lg_tall:text-content-size mt-5">
          <p class="font-semibold lg:basis-[12%] lg_tall:basis-[12%] sm:basis-[18%]">Tel</p>
          <p class="">02-2085-0606</p>
        </div>
        <div class="flex lg:text-content-size mt-5">
          <p class="font-semibold lg:basis-[12%] lg_tall:basis-[12%] sm:basis-[18%]">Fax</p>
          <p class="">02-2085-0607</p>
        </div>
        <div class="h-[600px] mt-10 mx-auto" id="map"></div>
      </div>
    </div>

    <CompanyHistory class="hidden" />

    <!-- 고객들과 함께 걸어간다. -->
    <p class="hidden mt-24 text-5xl font-semibold">고객들과 함께 걸어가고 있습니다.</p>

    <div class="flex-wrap items-center hidden gap-10 mt-20">
      <img class="w-[20%]" src="@/assets/ic_lg.jpg" alt="">
      <img class="w-[20%]" src="@/assets/ic_coway.png" alt="">
      <img class="w-[20%]" src="@/assets/ic_oliveyoung.png" alt="">
      <img class="w-[20%]" src="@/assets/ic_tmap.png" alt="">
      <img class="w-[20%]" src="@/assets/ic_toss.png" alt="">
      <img class="w-[20%]" src="@/assets/ic_vp.png" alt="">
      <img class="w-[20%]" src="@/assets/ic_yesone.png" alt="">
      <img class="w-[20%]" src="@/assets/ic_yogiyo.png" alt="">
    </div>

    <div class="grid items-center hidden grid-cols-4 grid-rows-2 gap-5 mt-20">
      <img class="" src="@/assets/ic_lg.jpg" alt="">
      <img class="" src="@/assets/ic_coway.png" alt="">
      <img class="" src="@/assets/ic_oliveyoung.png" alt="">
      <img class="" src="@/assets/ic_tmap.png" alt="">
      <img class="" src="@/assets/ic_toss.png" alt="">
      <img class="" src="@/assets/ic_vp.png" alt="">
      <img class="" src="@/assets/ic_yesone.png" alt="">
      <img class="" src="@/assets/ic_yogiyo.png" alt="">
    </div>



  </div>
  <Footer />
</template>
<script>

import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
import CompanyStory from '@/components/baseComponents/CompanyStory.vue'
import CompanyHistory from '@/components/baseComponents/CompanyHistory.vue'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Header, Footer, CompanyHistory, CompanyStory, Carousel, Slide, Pagination, Navigation,
  },
  data() {
    return {
      //회사 히스토리 데이터
      companyHistoryData: {
        2020: [{ 'day': '2020. 01', 'content': '1월 리드워크 법인설립' },
        { 'day': '2020. 01', 'content': '예비 창업 패키지 선정 (중소기업벤처부)' }
          , { 'day': '2020. 04', 'content': '10월 벤처기업 승인 / 9월 기술평가 우수 기업 인증 (T-5) / 4월 연구 전담부서 설립' }
          , { 'day': '2020. 04', 'content': '4월 증강현실 기술 특허출원 / 9월 겟아이즈 (Get eyes) 상표등록출원' }
          , { 'day': '2020. 06', 'content': '20 초기 창업 패키지 선정 (중소기업벤처부)' }
          , { 'day': '2020. 06', 'content': '안드로이드 베타 서비스 출시' }
          , { 'day': '2020. 07', 'content': '19 예비 창업 패키지 결과 : 최우수기업 선정 (중소기업벤처부)' }
          , { 'day': '2020. 12', 'content': '12월 장관상 수상 (과학기술정보통신부)' }
          , { 'day': '2020. 12', 'content': '12월 - 시드 투자 유치 (두루그룹)' }],
        2021: [{ 'day': '2021. 01', 'content': '안드로이드 베타 서비스 출시' }
          , { 'day': '2021. 01', 'content': '안드로이드, iOS 2차 베타 서비스' }
          , { 'day': '2021. 03', 'content': '3월 리드워크 본사 확장 이전' }
          , { 'day': '2021. 05', 'content': '20 K-water IR 데모데이 최우수상 수상' }
          , { 'day': '2021. 06', 'content': '20 초기 창업 패키지 결과 : 최우수기업 선정 (중소기업벤처부)' }
          , { 'day': '2021. 06', 'content': '환경부 법정법인 - 환경보전협회 회원사 등재' }
          , { 'day': '2021. 07', 'content': '안드로이드 소프트 런칭' }
          , { 'day': '2021. 09', 'content': '겟아이즈 - iOS 인기차트 21위 달성' }
        ],
        2022: [{ 'day': '2022. 01', 'content': '겟아이즈 (Get eyes) 상표등록' }],
        2023: [{ 'day': '2023. 03', 'content': '겟아이즈 - 체험단 서비스 오픈' },
        { 'day': '2023. 09', 'content': '겟아이즈 - 선물하기 서비스 오픈' },
        { 'day': '2023. 12', 'content': '큐밋 베타버전 오픈 준비' }],
      },
      selectedYear: '2023',

      // 윈도우의 너비를 추적합니다.
      windowWidth: window.innerWidth,

      slides: [
        { image: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202312/story_img_0002.jpg', content: '(주)리드워크(대표 이재문)가 운영 중인 제품 자산 전반을 원스톱으로 관리해주는 ‘겟아이즈(Get eyes)’에이미지 인식 기술을 기반으로 제품을 바로 등록할 수 있는 신규 기능을 추가했다고 15일 밝혔다.', title: '‘겟아이즈’ 이미지 인식 기반 제품 등록 기능 새롭게 선보여', clickUrl: 'https://www.itbiznews.com/news/articleView.html?idxno=49215' },
        { image: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/story_img_0004.jpg', content: '종이 설명서 없이 카메라로 제품을 인식하기만 하면 사용법을 알 수 있죠', title: 'AR 기술로 전자제품 사용법 알려주는 친절한 앱 ‘겟아이즈', clickUrl: 'https://magazine.hankyung.com/job-joy/article/202101059904b' },
        { image: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/story_img_0005.jpg', content: '컴퓨터로 구현된 가상공간에서 실제와 유사한 체험을 가능케 하는 VR(가상현실), 현실세계에 새로운 가상 이미지를 추가하는 AR(증강현실)은 미래를 이끌 차세대 기술로 늘 주목을 받아왔다.', title: "실생활에 쓸모있는 AR 기술 '리드워크' - VR AR EXPO 2020", clickUrl: 'https://www.ilovepc.co.kr/news/articleView.html?idxno=35904' },
        { image: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/story_img_0006.jpg', content: '리드워크가 제품 자산 전반을 원스톱으로 관리해주는 ‘겟아이즈’를 론칭했다.', title: '리드워크, 물건 관리 서비스 ‘겟아이즈’ 안드로이드 버전 7월 정식 론칭', clickUrl: 'https://platum.kr/archives/166211' }
      ],
    }
  },
  created() {
    // 윈도우 크기가 변경될 때마다 windowWidth 데이터를 업데이트합니다.
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    itemsToShow() {
      // Tailwind의 반응형 유틸리티를 사용하여 클래스를 동적으로 변경
      return this.windowWidth >= 1080 ? '3' : '1';
    },
    currentYearData() {
      return this.companyHistoryData[this.selectedYear] || [];
    }
  },
  methods: {
    handleClick(year) {
      this.selectedYear = year;
      this.animateData();
    },
    animateData() {
      gsap.fromTo(this.$refs.yearData, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.2 });

      let startYpos = 100;
      let duration = 0.6;
      let scrollStart = 'top 90%'
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(".up-animation-0",
        { y: startYpos, opacity: 0 }, // 시작 값
        {
          y: 0, // 종료 위치 (y축으로 100px 위로)
          delay: 0.2,
          opacity: 1, // 종료 투명도 (완전 불투명)
          duration: duration, // 애니메이션 지속 시간 1초
          ease: "power1.out", // 부드러운 애니메이션 효과
          // ScrollTrigger 설정
          scrollTrigger: {
            trigger: ".up-animation-0", // 이 애니메이션이 트리거 될 요소
            start: scrollStart, // 뷰포트의 상단에서 요소의 상단이 80% 지점에 도달했을 때 시작      
            toggleActions: "restart pause resume pause" // 스크롤 동작에 따른 애니메이션 제어
          }
        }
      );
      gsap.fromTo(".up-animation-1",
        { y: startYpos, opacity: 0 }, // 시작 값
        {
          y: 0, // 종료 위치 (y축으로 100px 위로)
          delay: 0.3,
          opacity: 1, // 종료 투명도 (완전 불투명)
          duration: duration, // 애니메이션 지속 시간 1초
          ease: "power1.out", // 부드러운 애니메이션 효과
          // ScrollTrigger 설정
          scrollTrigger: {
            trigger: ".up-animation-1", // 이 애니메이션이 트리거 될 요소
            start: scrollStart, // 뷰포트의 상단에서 요소의 상단이 80% 지점에 도달했을 때 시작      
            toggleActions: "restart pause resume pause" // 스크롤 동작에 따른 애니메이션 제어
          }
        }
      );
      gsap.fromTo(".up-animation-2",
        { y: startYpos, opacity: 0 }, // 시작 값
        {
          y: 0, // 종료 위치 (y축으로 100px 위로)
          delay: 0.4,
          opacity: 1, // 종료 투명도 (완전 불투명)
          duration: duration, // 애니메이션 지속 시간 1초
          ease: "power1.out", // 부드러운 애니메이션 효과
          // ScrollTrigger 설정
          scrollTrigger: {
            trigger: ".up-animation-2", // 이 애니메이션이 트리거 될 요소
            start: scrollStart, // 뷰포트의 상단에서 요소의 상단이 80% 지점에 도달했을 때 시작      
            toggleActions: "restart pause resume pause" // 스크롤 동작에 따른 애니메이션 제어
          }
        }
      );
      gsap.fromTo(".up-animation-3",
        { y: startYpos, opacity: 0 }, // 시작 값
        {
          y: 0, // 종료 위치 (y축으로 100px 위로)
          delay: 0.5,
          opacity: 1, // 종료 투명도 (완전 불투명)
          duration: duration, // 애니메이션 지속 시간 1초
          ease: "power1.out", // 부드러운 애니메이션 효과
          // ScrollTrigger 설정
          scrollTrigger: {
            trigger: ".up-animation-3", // 이 애니메이션이 트리거 될 요소
            start: scrollStart, // 뷰포트의 상단에서 요소의 상단이 80% 지점에 도달했을 때 시작      
            toggleActions: "restart pause resume pause" // 스크롤 동작에 따른 애니메이션 제어
          }
        }
      );
    },
  },
  mounted() {
    //this.animateData(); // 초기 애니메이션

    //네이버 지도 api 로드
    const script = document.createElement("script");
    script.src = "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=nd9f34b6hi";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      // 네이버 지도 생성
      let map = new window.naver.maps.Map("map", {
        center: new window.naver.maps.LatLng(37.479253, 126.8860766),
        zoom: 17,
      });

      new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(37.479253, 126.8860766),
        map: map
      });
    };
  }
}
</script>
