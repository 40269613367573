import { createRouter, createWebHistory } from "vue-router";
import Home from '@/views/Home.vue'
import CompanyIntroduction from '@/views/CompanyIntroduction.vue'
import SiIntroduction from '@/views/SiIntroduction.vue'
import GeteyesIntroduction from '@/views/GeteyesIntroduction.vue'
import Recruit from '@/views/Recruit.vue'
import ContactUs from '@/views/ContactUs.vue'
import Qmeet from '@/views/Qmeet.vue'
import Terms from '@/views/Terms.vue'

export default createRouter({
  history: createWebHistory(),  
  scrollBehavior() {
    // 항상 페이지의 상단으로 이동
    return { top: 0 };
  },
  routes: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/company',
      component: CompanyIntroduction,
    },
    {
      path: '/testingservice',
      component: SiIntroduction,
    },
    {
      path: '/geteyes',
      component: GeteyesIntroduction,
    },
    {
      path: '/recruit',
      component: Recruit,
    },
    {
      path: '/contactus',
      component: ContactUs,
    },
    {
      path: '/qmeet',
      component: Qmeet,
    },
    {
      path: '/terms',
      component: Terms,
    },
  ],
});
