<template>
  <div class="mr-5 cursor-pointer" @click="openSite">    
    <img class="hover:rounded-3xl rounded-3xl w-[700px] h-[400px] object-cover" :src="image" alt="loading">
    <p class="mt-6 text-text-2 text-left font-semibold text-content-size line-clamp-1">{{ title }}</p>
    <p class="mt-6 text-text-2 text-left line-clamp-2">{{ content }}</p>
  </div>
</template>

<script>
export default {

  props: {
    image: String,
    title: String,
    content: String,
    clickUrl: String,
  },
  methods:{
    openSite(){
      window.open(this.clickUrl, '_blank');
    }
  }
}
</script>